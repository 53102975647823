<template>
  <div
    class="form-input"
    :class="{
      error: error,
      success: success
    }"
  >
    <label
      v-if="label"
      class="label"
      :for="`input-${uuid}`"
    >
      {{ label }}
    </label>
    <label
      v-if="caption"
      :for="`input-${uuid}`"
      class="caption"
    >
      {{ caption }}
    </label>
    <input
      :id="`input-${uuid}`"
      :placeholder="placeholder"
      :style="{ background: images }"
      :class="{
        'before-icon': beforeIcon,
        'after-icon': afterIcon,
      }"
      :value="value"
      v-bind="attrs"
      @input="input"
      v-on="listeners"
    >
    <label
      v-if="hint"
      :for="`input-${uuid}`"
      class="hint"
    >
      <i
        v-if="error"
        class="vertical-bottom ri-error-warning-line"
      />
      <i
        v-if="success"
        class="vertical-bottom ri-thumb-up-line"
      />
      {{ hint }}
    </label>
  </div>
</template>

<script>
let uuid = 0
export default {
  name: 'FormInput',
  inheritAttrs: false,
  props: {
    beforeIcon: {
      required: false,
      default: '',
      type: String,
    },
    afterIcon: {
      required: false,
      default: '',
      type: String,
    },
    label: {
      required: false,
      default: '',
      type: String,
    },
    caption: {
      required: false,
      default: '',
      type: String,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    hint: {
      required: false,
      default: '',
      type: String,
    },
    error: {
      required: false,
      default: false,
      type: Boolean,
    },
    success: {
      required: false,
      default: false,
      type: Boolean,
    },
    value: {
      required: false,
      default: '',
      type: String,
    },
  },
  computed: {
    images () {
      const basePath = '/images/r-icons/'
      let background = ''
      if (this.beforeIcon) {
        background = `url(${basePath}/${this.beforeIcon}) left no-repeat`
      }
      if (this.afterIcon) {
        background = `url(${basePath}/${this.afterIcon}) right no-repeat`
      }
      if (this.beforeIcon && this.afterIcon) {
        background = `url('${basePath}/${this.beforeIcon}') left no-repeat, url('${basePath}/${this.afterIcon}') right no-repeat`
      }
      return background
    },
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
  beforeCreate () {
    this.uuid = uuid.toString()
    uuid += 1
  },
  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
