<template>
  <b-container
    fluid
    class="p-0 main-container"
  >
    <b-form
      class="row vh-100"
      @submit.prevent="onSubmit"
    >
      <b-col
        class="mt-5"
        lg="6"
      >
        <b-row class="mt-5 mb-5">
          <b-col class="text-center h7">
            Find your next dream project with Relancer
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center m-2">
          <b-col lg="8">
            <b-row>
              <b-col sm="6">
                <FormInput
                  v-model="first_name"
                  placeholder="First name"
                  label="First name"
                  required
                  :error="!!errors.first_name"
                  :hint="errors.first_name"
                />
              </b-col>
              <b-col sm="6">
                <FormInput
                  v-model="last_name"
                  placeholder="Last name"
                  label="Last name"
                  required
                  :error="!!errors.last_name"
                  :hint="errors.last_name"
                />
              </b-col>
            </b-row>
            <b-row class="my-4">
              <b-col>
                <FormInput
                  v-model="agency_name"
                  placeholder="Recruiting Agency"
                  label="Agency name"
                  required
                  :error="!!errors.agency_name"
                  :hint="errors.agency_name"
                />
              </b-col>
            </b-row>
            <b-row class="my-4">
              <b-col>
                <FormInput
                  v-model="email"
                  placeholder="email@example.com"
                  label="Email address"
                  type="email"
                  required
                  :error="!!errors.email"
                  :hint="errors.email"
                />
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col>
                <FormInput
                  v-model="password"
                  placeholder="Password (min 8 characters)"
                  label="Password"
                  type="password"
                  required
                  :error="!!errors.password"
                  :hint="errors.password"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center my-4">
                <b-btn
                  variant="primary"
                  class="step1-btn"
                  type="submit"
                  :disabled="submitting"
                >
                  Sign up
                </b-btn>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center h9">
            <p class="mb-0">
              By signing up, I agree to Relancer's
              <a
                target="_blank"
                href="https://relancer.com/terms/recruiter-terms"
              >
                Terms of Service</a>
              and
              <a
                target="_blank"
                href="https://relancer.com/terms/privacy"
              >
                Privacy Policy
              </a>.
            </p>
          </b-col>
        </b-row>
        <hr>
        <b-row class="mt-3">
          <b-col class="text-center h9">
            <p>
              Already have an account?
              <router-link to="/login">
                Sign in
              </router-link>
            </p>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col class="text-center mt-4">
            <a href="https://relancer.com/">
              <LogoWithTextDark />
            </a>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="d-none d-lg-block p-0 background-image" />
    </b-form>
  </b-container>
</template>

<script>
import LogoWithTextDark from '@/components/svgs/LogoWithTextDark'
import { vuexMapper } from '@/helpers/vuexMapper'
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput'

export default {
  name: 'InitialStep',
  components: {
    LogoWithTextDark,
    FormInput,
  },
  data () {
    return {
      submitting: false,
      errors: {},
    }
  },
  computed: {
    ...vuexMapper({
      fields: ['first_name', 'last_name', 'agency_name', 'email', 'password'],
      base: 'agency',
      nestedObject: 'signupForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  methods: {
    ...mapActions('agency', ['submitSignup']),

    onSubmit () {
      this.submitting = true
      this.submitSignup()
        .then(token => {
          this.$router.push('/signup/agency/goals?token=' + token)
        })
        .catch(({ response }) => {
          if (Array.isArray(response?.data?.errors)) {
            response.data.errors.forEach(error => {
              this.errors[error.field] = error.message
            })
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },

}
</script>

<style lang="scss" scoped>

.main-container {
  background: #F8F6F7;

  .background-image {
    background-image: url('/images/signup_testmonials.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .step1-btn {
    width: 100%;
    height: 40px;
    background: #EE8B7B;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
  }
}
</style>
